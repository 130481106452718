import React from "react";

import { Redirect, withRouter } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import parse from "html-react-parser";

import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { RemoteBackground } from '../../components/picture';

import Header from "../../layout/header/header";
import ProjectService from './projectService';

class ProjectDetail extends React.Component {
    interval = null;

    constructor(props) {
        super(props)

        this.state = {
            projects: []
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.getData();
        }, 20000);

        this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getData = () => {
        ProjectService.getProjects(0, 10).then((res) => {
            const data = res.data;
            this.setState({
                projects: data.models
            });
        });
    }

    render() {
        const { t } = this.props;
        const projects = this.state.projects;

        const { location } = this.props

        if (location.state === undefined) {
            return <Redirect to="/project" />
        }

        const selectedProject = location.state.projectDetail;


        return (
            <React.Fragment>
                <Header heroImage="project.jpg" activeSite="project" />

                <section className="ftco-degree-bg">
                    <div className="container">
                        <div className="row">
                            <div className="blog-entry align-self-stretch col-md-8" style={{padding: '0'}}>
                                <RemoteBackground className="block-20" name={selectedProject.picture} type="a"/>
                                <div className="text p-4 d-block">
                                    <h2 className="mb-3">{parse(selectedProject.title)}</h2>
                                    <div>{parse(selectedProject.text)}</div>
                                </div>
                            </div>
                            <div className="col-md-4 sidebar">
                                <div className="sidebar-box">
                                    <h3>{t('projectDetail.sidebar.title')}</h3>
                                    {
                                        projects.map(
                                            (project, index) =>
                                            <Scroll key={index} to="content" spy={true} smooth={true}>
                                                <Link to={{
                                                    pathname: '/projectDetail',
                                                    state: {
                                                        projectDetail: project
                                                    }
                                                }}>
                                                <div className="block-21 mb-4 d-flex">
                                                    <RemoteBackground className="blog-img mr-4" name={project.pictureThumbnail} type="span"/>
                                                    <div className="text">
                                                        <h3 className="heading">{parse(project.title)}</h3>
                                                        <div className="meta">
                                                            <div className="meta mb-3">
                                                                <span className="icon-calendar"></span> {t('app.technical.date.short', { date: new Date(project.createdOn) })}
                                                                <span className="icon-person" style={{paddingLeft:'10px'}}></span> {parse(project.author)}
                                                            </div>
                                                            <div className="meta mb-3">
                                                                <span className="icon-map-o"></span> {parse(project.locationName)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>
                                            </Scroll>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(withRouter(ProjectDetail))
