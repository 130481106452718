import React from 'react';

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { RemoteBackground } from '../../components/picture';
import Header from '../../layout/header/header';

function Imprint() {
	const { t } = useTranslation();
    
	return (
        <React.Fragment>
            <Header heroImage="bg_2.jpg" activeSite="imprint" />
            <section id="section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-12">
                            <div className="blog-entry align-self-stretch">
                                <div className="text d-block m-4">
                                <div className="row d-flex">
                                    <div className="col-md-6 pl-md-5">
                                        <h2 className="mb-4">{parse(t('imprint.title'))}</h2>
                                        {parse(t('imprint.text'))}
                                    </div>
                                    <div className="col-md-6 d-flex">
                                        <RemoteBackground className="img img-about align-self-stretch" name="bg_3.jpg" style={{ width: '100%' }}/>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </React.Fragment>
    )
}
export default Imprint;