import React from 'react';
import { useTranslation } from "react-i18next";
import { Link as Scroll } from 'react-scroll';

function NoContent() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <section id="section">
                <div className="container card border border-light shadow-0 mb-3">
                <Scroll to="content" spy={true} smooth={true}>
                    <div className="card-body">
                        <h2 className="card-title text-center">{t('nocontent.title')}</h2>
                        <p className="card-text text-center">
                            <div className="row d-flex mb-4 contact-info">
                                <div className="col-md-4 mb-4"/>
                                <div className="col-md-4 mb-4">
                                <p><img src="not_available.svg" alt="Not Available" style={{width: '30%'}}/></p>
                                    <p>{t('nocontent.text')}</p>
                                </div>                        
                            </div>
                        </p>
                    </div>
                </Scroll>
                </div>
            </section>
        </React.Fragment>
    )
}

function NoContentSmall() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <img src="not_available.svg" alt="Not Available" style={{width: '15%'}}/>
            <div className="block-21 mb-4 d-flex" style={{textAlign: 'top'}}>
                <div className="text">
                    <h1 className="h4" style={{color: 'rgb(248, 111, 45)'}}>{t('nocontent.title')}</h1>
                        <div className="meta">
                            <div className="meta mb-3">
                                <p>{t('nocontent.text')}</p>
                            </div>
                        </div>
                    </div>
            </div>
        </React.Fragment>
    )
}

export { NoContent, NoContentSmall }
