import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

function PrivacyImprint() {
    const { t } = useTranslation();

    return (
        <Scroll to="content" spy={true} smooth={true} duration={2000}>
        <div className="row">
            <div className="col-md-12 text-center">
                <Link to="/privacy" className="py-2 d-block">{t("links.privacy")}</Link>
                <Link to="/imprint" className="py-2 d-block">{t("links.imprint")}</Link>
            </div>
        </div>
        </Scroll>
    )
}
export default PrivacyImprint;