import * as envConstants from "../../env/constants";
import StaticContentService from '../../base/staticContentService';


class HomeService {
    
    getHomeData() {
        return StaticContentService.getData(envConstants.HOME_URL);
    }

    sendMail(nameValue, emailValue, messageValue) {
        /*
        let payload = {
            languageTag: i18n.language,
            name: nameValue,
            email: emailValue,
            message: messageValue
        };
        */
        //return axios.post(EMAIL_PATH, payload);
        return null;
    }

}
export default new HomeService();