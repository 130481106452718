import { PICTURE_URL } from '../env/constants'

function RemoteImage (props) {
    const {alt, name, ...imageProps} = props;
    const imageAlt = alt ? alt : name;
    return <img src={PICTURE_URL + name} alt={imageAlt} {...imageProps}></img>;

}

function RemoteBackground (props) {

    const {style, type, name, ...imageProps} = props;
    const imageUrl = 'url(' + PICTURE_URL + name + ')';

    const styleBackground = {backgroundImage: imageUrl};

    if(type === 'section') {
        return <section style={{...style, ...styleBackground}} {...imageProps}/>;
    }
    if(type === 'span') {
        return <span style={{...style, ...styleBackground}} {...imageProps}/>;
    }
    if(type === 'a') {
        return <a style={{...style, ...styleBackground}} {...imageProps}> </a>;
    }
    return <div style={{...style, ...styleBackground}} {...imageProps}/>;

}

export { RemoteImage, RemoteBackground, PICTURE_URL };