import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";

import { Link } from 'react-router-dom';

import { RemoteBackground } from '../../components/picture';

import BlogService from './blogService';
import Header from '../../layout/header/header';

import { LoadingSpinner } from '../../components/loadingspinner';
import { NoContent } from '../../components/nocontent';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../locales/i18n';

function Blog() {

    const { t } = useTranslation();
    const reloadOnLocaleChanged = useLocale();
    
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverDown, setServerDown] = useState(false);

    useEffect(() => {
        BlogService.getBlogs()
            .then((res) => {
                setBlogs(res.data.models);
                setLoading(false);
                setServerDown(false);                
            })
            .catch(() => {
                setBlogs([]);
                setLoading(false);
                setServerDown(true);
            });
        
    }, [reloadOnLocaleChanged]);

    return (
        <div>
            <Header heroImage="blog.jpg" activeSite="blog" />
            <section id="section">
                <div className="container" style={{textAlign: 'center'}}>
                    { loading ? <LoadingSpinner /> :
                        <div className="row d-flex">
                            { serverDown || blogs.length === 0 ? <NoContent/> : 
                                blogs.map(
                                    (blog, index) =>
                                        <div key={index} className="col-md-4 d-block">
                                            <div className="blog-entry align-self-stretch">
                                                    <Link to={{
                                                        pathname: '/blogDetail',
                                                        state: {
                                                            blogDetail: blog
                                                        }
                                                    }}>
                                                        <RemoteBackground className="block-20" name={blog.pictureThumbnail} type="span"/>
                                                        <div className="text p-4 d-block">
                                                            <div className="meta mb-3">
                                                                <span className="icon-calendar"></span> {t('app.technical.date.short', { date: new Date(blog.publishAt) })}
                                                                <span className="icon-person" style={{paddingLeft:'10px'}}></span> {parse(blog.author)}
                                                            </div>
                                                            <h3 className="heading mt-3">{parse(blog.title)}</h3>
                                                            <div style={{color: '#0d0d0d'}} >
                                                            {
                                                                blog.text &&  
                                                                parse(blog.text.substring(0, 300) 
                                                                    + ' ' + (blog.text.length > 300 ? '...' : ''))
                                                            }
                                                            </div>
                                                        </div>
                                                        
                                                    </Link>
                                            </div>    
                                        </div>
                                )
                            }
                        </div>
                        }
                </div>
            </section>
        </div>
    )
}
export default Blog;