import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import parse from "html-react-parser";
import { Fade } from "react-awesome-reveal";
import AnimatedNumber from 'react-animated-number';
import { toast } from 'react-toastify';

import Header from '../../layout/header/header';
import { RemoteBackground } from '../../components/picture';

import HomeService from './homeService';

import { useLocale } from '../../locales/i18n';
import { LoadingSpinner } from '../../components/loadingspinner';

function Home() {
	const { t } = useTranslation();
	
	//const [wantToHelpName, setWantToHelpName] = useState('');
	//const [wantToHelpEmail, setWantToHelpEmail] = useState('');
	//const [wantToHelpMessage, setWantToHelpMessage] = useState('');
	
	// TODO set to useState(false) when mail function works
	//const [disableSendMail, setDisableSendMail] = useState(false);
	const [homeData, setHomeData] = useState('');
	const [loading, setLoading] = useState(true);
    const reloadOnLocaleChanged = useLocale();

	const sendWantToHelpMail = () => {
		// TODO
		toast(t('home.wannasupport.messages.mailNotSent'));
/*
		HomeService.sendMail(wantToHelpName, wantToHelpEmail, wantToHelpMessage)
		.then((res) => {
			const hasBeenSend = !!res.data;
			if(hasBeenSend) {
				toast(t('home.wannasupport.messages.mailSent'));
			} else {
				toast(t('home.wannasupport.messages.mailNotSent'));
			}
		})
		.catch(error => {
			if(!error || !error.response) {
				toast(t('home.wannasupport.messages.mailNotSentError'));
			}
			else if(error.response.status === 429) {
				toast(t('home.wannasupport.messages.mailLimitReached'));
				setDisableSendMail(true);
				const disableSendMailForSomeTime = setTimeout(() => {
					setDisableSendMail(false);
				  }, 20000);
				  return () => clearTimeout(disableSendMailForSomeTime);
			} else {
				toast(t('home.wannasupport.messages.mailNotSent'));
			}
		});
		*/
	}

	useEffect(() => {
        HomeService.getHomeData()
        .then((response) => {
            setLoading(false);
            if(response.data.model) {	
				setHomeData(response.data.model);
            }
        })
        .catch(() => {
            setLoading(false);
            toast(t('home.wedo.what.messages.failedToLoadAmount'));
        });
    }, [reloadOnLocaleChanged, t]);

	return (
		<React.Fragment>
			<Header heroImage="index.jpg" activeSite="" />
			<Fade bottom>              
			<section className="ftco-counter ftco-intro" id="section-counter" style={{marginTop: '-20px'}}>
			{ loading ? <LoadingSpinner /> : <div className="container text-left">
						<div className="row no-gutters">
							<div className="row no-gutters">
							<div className="col-md-4 d-flex block-18 color-1 shadow-lg p-1 mb-0 rounded">
									<div className="block-18 color-1 align-items-stretch">
										<div>
											<h3>{t("home.wedo.what.title")}</h3>
											<div>{t("home.wedo.what.pretext")}</div>
											<div>
												<strong>
													<AnimatedNumber value={homeData.kids_amount}
														style={{
															transition: '15s ease-out',
															fontSize: 48,
															transitionProperty: 'background-color, color, opacity'
														}}
														className="text-dark"
														duration={3000}
														formatValue={n => Math.round(n)} />
												</strong> {t("home.wedo.what.text")}
											</div>
											<div>{t("home.wedo.what.posttext")}</div>
										</div>
									</div>
								</div>
								<div className="col-md d-flex block-18 color-2 shadow-lg p-1 mb-0 rounded">
									<div className="block-18 color-2 align-items-stretch">
										<div className="text">
											<h3 className="mb-4">{t("home.wedo.where.title")}</h3>
											<div>{t("home.wedo.where.text")}</div>
											<div style={{position: 'absolute', right: '20px',bottom: '20px'}}><a target="_blank" href="http://maps.google.de/maps?f=q&source=s_q&hl=de&geocode=&q=Ashkar+Barisal,+Bangladesch&sll=51.151786,10.415039&sspn=12.109419,39.506836&ie=UTF8&hq=&hnear=Ashkar,+Barishal,+Bangladesch&t=h&ll=22.917923,90.175781&spn=36.085914,77.607422&z=4" rel="noreferrer" className="btn btn-white px-3 py-2 mt-2">{t("home.wedo.where.button")}</a></div>
										</div>
									</div>
								</div>
								<div className="col-md d-flex block-18 color-3 shadow-lg p-1 mb-0 rounded">
									<div className="block-18 color-3 align-items-stretch">
										<div className="text">
											<h3 className="mb-4">{t("home.wedo.how.title")}</h3>
											<div>{t("home.wedo.how.text")}</div>
											<div style={{position: 'absolute', right: '20px',bottom: '20px'}}><Link to="/project" className="btn btn-white px-3 py-2 mt-2">{t("home.wedo.how.button")}</Link></div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</div>
			}
			</section >
			</Fade>
			
			<Fade right>
			<section className="ftco-section">
				<div className="container text-left bg-light shadow-lg p-1 mb-0 rounded">
						<div className="row">
							<div className="col-md-4 d-flex align-self-stretch">
								<div className="media block-6 d-flex services p-3 py-4 d-block">
									<div className="icon d-flex mb-3"><span className="flaticon-charity"></span></div>
									<div className="media-body pl-4">
										<h3 className="heading">{t("home.youcan.helptohelp.title")}</h3>
										{parse(t("home.youcan.helptohelp.text"))}
									</div>
								</div>
							</div>
							<div className="col-md-4 d-flex align-self-stretch">
								<div className="media block-6 d-flex services p-3 py-4 d-block">
									<div className="icon d-flex mb-3"><span className="flaticon-donation-1"></span></div>
									<div className="media-body pl-4">
										<h3 className="heading">{t("home.youcan.silentlysupport.title")}</h3>
										{parse(t("home.youcan.silentlysupport.text"))}
									</div>
								</div>
							</div>
							<div className="col-md-4 d-flex align-self-stretch">
								<div className="media block-6 d-flex services p-3 py-4 d-block">
									<div className="icon d-flex mb-3"><span className="flaticon-donation"></span></div>
									<div className="media-body pl-4">
										<h3 className="heading">{t("home.youcan.donate.title")}</h3>
										{parse(t("home.youcan.donate.text"))}
									</div>
								</div>
							</div>
						</div>
				</div>
			</section>
			</Fade>
			
			<Fade right>
			<RemoteBackground className="ftco-section-3 img" name="bg_3.jpg" type="section">
				<div className="overlay"></div>
				<div className="container">
						<div className="row d-md-flex">
							<div className="col-md-6 d-flex">
								<RemoteBackground className="img img-2 align-self-stretch shadow rounded p-2 mb-5" name="bg_4.jpg"/>
							</div>
							<div className="col-md-6 volunteer pl-md-5">
								<h3 className="mb-3">{t("home.wannasupport.title")}</h3>
								<div className="volunter-form">
									<div className="form-group">
										<input 
											type="text" 
											className="form-control" 
											placeholder={t('home.wannasupport.name')}
											//onChange={event => setWantToHelpName(event.target.value)} 
										/>
									</div>
									<div className="form-group">
										<input 
											type="text" 
											className="form-control" 
											placeholder={t('home.wannasupport.email')} 
											//onChange={event => setWantToHelpEmail(event.target.value)} 
										/>
									</div>
									<div className="form-group">
										<textarea 
											name="" 
											id="" 
											cols="30" 
											rows="3" 
											className="form-control" 
											placeholder={t('home.wannasupport.message')}
											//onChange={event => setWantToHelpMessage(event.target.value)} 
										/>
									</div>
									<div className="form-group">
										<button className="btn btn-white py-3 px-5" disabled={true} onClick={sendWantToHelpMail}>{t('home.wannasupport.button')}</button>
									</div>
								</div>
							</div>
						</div>
				</div>
			</RemoteBackground>
			</Fade>
			
		</React.Fragment >
	)
}
export default Home;