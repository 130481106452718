import React from 'react';

import { Link as Scroll } from 'react-scroll';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useTranslation } from 'react-i18next';

function Spinner(props) {
    const width = props.width;
    const height = props.height;
    return (
        <Loader type="Bars" color="#d8632b" height={height} width={width} />
    );
}

function LoadingSpinner() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <section id="section">
                <div className="container card border border-light shadow-0 mb-3">
                <Scroll to="content" spy={true} smooth={true}>
                    <div className="card-body">
                        <h2 className="card-title text-center">{t('nocontent.loading')}</h2>
                        <p className="card-text text-center">
                            <div className="row d-flex mb-4 contact-info">
                                <div className="col-md-4 mb-4"/>
                                <div className="col-md-4 mb-4">
                                    <p><Spinner width={150} height={150}/></p>
                                </div>                        
                            </div>
                        </p>
                    </div>
                </Scroll>
                </div>
            </section>
        </React.Fragment>
    )
}

function LoadingSpinnerSmall() {
    return (
        <React.Fragment>
            <Spinner width={60} height={60}/>
        </React.Fragment>
    )
}
export { LoadingSpinner, LoadingSpinnerSmall }
