
import { useEffect, useState } from 'react';
import { RemoteBackground } from '../../components/picture';

import { isMobile } from "react-device-detect";

function Hero(props) {
    const [offset, setOffset] = useState(isMobile ? 0 : window.pageYOffset)

    useEffect(() => {
      function handleScroll() {
        setOffset(window.pageYOffset)
      }
  
      window.addEventListener("scroll", handleScroll)
  
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, [])

    return (
        <RemoteBackground className="hero-wrap" name={props.image} style={{transform: `translateY(${offset * 0.5}px)`}}>
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters align-items-center justify-content-center">
                    <span id="main-content"></span>
                </div>
            </div>
        </RemoteBackground>
    )
}

export default Hero;