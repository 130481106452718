import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../layout/header/header';

function NotFound() {
    const { t } = useTranslation();

	return (
        <React.Fragment>
            <Header heroImage="bg_1.jpg" activeSite="" />

            <section className="ftco-section contact-section ftco-degree-bg">
                <div className="container">
                    <div className="row d-flex contact-info" style={{textAlign: 'center'}}>
                        <div className="col-md-12 mb-4">
                            <h2 className="h1">{t('notfound.title')}</h2>    
                        </div>
                        <div className="col-md-12 mb-4">
                            <img src="not_available.svg" alt="Not Available" style={{width: '15%'}}/>
                        </div>
                        <div className="col-md-12 mb-4">
                            <p>{t('notfound.text')}</p>
                        </div>                        
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default NotFound;