import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";

import { Link } from 'react-router-dom';
import { RemoteBackground } from '../../components/picture';

import ProjectService from './projectService';
import Header from '../../layout/header/header';

import { LoadingSpinner } from '../../components/loadingspinner';
import { NoContent } from '../../components/nocontent';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../locales/i18n';

function Project() {

    const { t } = useTranslation();
    const reloadOnLocaleChanged = useLocale();
    
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverDown, setServerDown] = useState(false);

    useEffect(() => {
        ProjectService.getProjects()
            .then((res) => {
                setProjects(res.data.models);
                setLoading(false);
                setServerDown(false);                
            })
            .catch(() => {
                setProjects([]);
                setLoading(false);
                setServerDown(true);
            });
        
    }, [reloadOnLocaleChanged]);

    return (
        <div>
            <Header heroImage="project.jpg" activeSite="project" />
            <section id="section">
                <div className="container" style={{textAlign: 'center'}}>
                    { loading ? <LoadingSpinner /> :
                        <div className="row d-flex">
                            { serverDown || projects.length === 0 ? <NoContent/> : 
                                projects.map(
                                    (project, index) =>
                                        <div key={index} className="col-md-4 d-block">
                                            <div className="blog-entry align-self-stretch">
                                                    <Link to={{
                                                        pathname: '/projectDetail',
                                                        state: {
                                                            projectDetail: project
                                                        }
                                                    }}>
                                                        <RemoteBackground className="block-20" name={project.pictureThumbnail} type="span"/>
                                                        <div className="text p-4 d-block">
                                                            <div className="meta mb-3">
                                                                <span className="icon-calendar"></span> {t('app.technical.date.short', { date: new Date(project.createdOn) })}
                                                                <span className="icon-person" style={{ paddingLeft: '10px' }}></span> {parse(project.author)}
                                                            </div>
                                                            <h3 className="heading mt-3">{parse(project.title)}</h3>
                                                            <div style={{color: '#0d0d0d'}} >
                                                            {
                                                                project.text &&  
                                                                parse(project.text.substring(0, 350) 
                                                                    + ' ' + (project.text.length > 350 ? '...' : ''))
                                                            }
                                                            </div>
                                                            <div className="meta mb-3">
                                                                <span className="icon-map-o"></span> {parse(project.locationName)}
                                                            </div>
                                                        </div>
                                                        
                                                    </Link>
                                            </div>    
                                        </div>
                                )
                            }
                        </div>
                        }
                </div>
            </section>
        </div>
    )
}
export default Project;