import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend'; 
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en/translation.json';
import translationDE from './de/translation.json';

import { format as formatDate, isDate } from "date-fns";
import { enUS, de } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const locales = { enUS, de };

const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

export const useLocale = () => {
    const { t } = useTranslation();
    return t('current_locale');
};

i18next
    .use(LocalStorageBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        load: 'languageOnly',
        interpolation: {
            format: (value, format, lng) => {
                
                if (isDate(value)) {
                    const locale = locales[lng];
                    if (format === "short") {
                        return formatDate(value, "P", { locale });
                    }
                    if (format === "long") {
                        return formatDate(value, "PPPP", { locale });
                    }
                    return formatDate(value, format, { locale });
                }
            },
            escapeValue: false
        },
        backend: {
            backends: [
              LocalStorageBackend,
              HttpApi // fallback
            ],
            backendOptions: [{
              /* options for primary backend */
            }]
        },
        detection: {
            lookupLocalStorage: 'asharalo-lng-sel',
        }
    });

export default i18next;