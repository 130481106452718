import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "./navbar";
import PrivacyImprint from "./privacyImprint"

import RecentBlogs from "./recentBlogs";
import ScrollToTop from 'react-scroll-up';

function Footer() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <footer className="ftco-footer ftco-section img">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-3">
                            <div className="ftco-footer-widget mb-4">
                                <h2 className="ftco-heading-2">{t('footer.about.title')}</h2>
                                <p style={{ textAlign: 'left' }}><span style={{ color: '#f86f2d' }}>{t('footer.about.pretext1')}</span> {t('footer.about.text1')}</p><p style={{ textAlign: 'left' }}><span style={{ color: '#f86f2d' }}>{t('footer.about.pretext2')}</span> {t('footer.about.text2')}</p>
                                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5"></ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <RecentBlogs/>
                        </div>

                        <Navbar />

                        <div className="col-md-3">
                            <div className="ftco-footer-widget mb-4">
                                <h2 className="ftco-heading-2">{t('footer.questions.title')}</h2>
                                <div className="block-23 mb-3">
                                    <ul>
                                        <li><span className="icon icon-map-marker"></span><span className="text">{t('app.name')}<br />{t('app.contact.street')}<br />{t('app.contact.city')}</span></li>
                                        <li><a href={'mailto:' + t('app.contact.email') + '?subject=I have a question!'}><span className="icon icon-envelope"></span><span className="text">{t('app.contact.email')}</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> <b>{t('app.name')}</b> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" rel="noreferrer" target="_blank">Colorlib</a>
                                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                            </p>
                        </div>
                    </div>
                    <PrivacyImprint/>
                </div>
                <ScrollToTop showUnder={160} duration={2000}>
                    <span id="scrollup" className="btn btn-primary btn-sm show"/>
                </ScrollToTop>
            </footer>
        </React.Fragment>
    )
}

export default Footer;