import React, { useEffect, useState } from 'react';

import parse from "html-react-parser";
import { RemoteBackground } from '../../components/picture';
import { useTranslation } from 'react-i18next';

import Header from '../../layout/header/header';
import AboutService from './aboutService';
import { useLocale } from '../../locales/i18n';
import { LoadingSpinner } from '../../components/loadingspinner';
import { NoContent } from '../../components/nocontent';
import { Fade } from "react-awesome-reveal";

function About() {

    const { t } = useTranslation();
    const reloadOnLocaleChanged = useLocale();
    
    const [aboutData, setAboutData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverDown, setServerDown] = useState(false);

    useEffect(() => {
        AboutService.getAboutData()
            .then((res) => {
                setAboutData(res.data.models);
                setLoading(false);
                setServerDown(false);                
            })
            .catch(() => {
                setAboutData([]);
                setLoading(false);
                setServerDown(true);
            });
        
    }, [reloadOnLocaleChanged]);

    return (
        <React.Fragment>
            <Header heroImage="board-member.jpg" activeSite="about" />

            <section id="section">
                <div className="container">
                { loading ? <LoadingSpinner /> : 
                <div>
                    { serverDown || aboutData.length === 0 ? <NoContent/> : 
                        aboutData.map(
                            (about, index) =>  
                            <Fade key={index} bottom>
                            <div key={index} className="row">
                                <div className="col-md-12">
                                    <div className="blog-entry align-self-stretch">
                                        <RemoteBackground className="block-20" name={about.picture} type="a"/>
                                        <div className="text p-4 d-block">
                                            <div className="meta mb-3">
                                                <span className="icon-calendar"></span>  {t('app.technical.date.short', { date: new Date(about.publishAt) })}
                                            </div>
                                            <h2>{parse(about.title)}</h2>
                                            {parse(about.text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Fade>
                    )}
                </div>
                }
                </div>
            </section>
        </React.Fragment >

    )
}
export default About;