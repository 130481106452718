import React, { useEffect, useState } from 'react';

import parse from "html-react-parser";
import Header from '../../layout/header/header';

import DonateService from './donateService';

import { LoadingSpinner } from '../../components/loadingspinner';
import { NoContent } from '../../components/nocontent';
import { useLocale } from '../../locales/i18n';

function Donate() {
    const [donate, setDonate] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [serverDown, setServerDown] = useState(false);
    const reloadOnLocaleChanged = useLocale();

    useEffect(() => {
        DonateService.getDonate()
        .then((response) => {
            setLoading(false);
            if(response.data.model) {
                setDonate(response.data.model);
            }
        })
        .catch(error => {
            setLoading(false);
            setServerDown(true);
            console.log(error);
        });
    }, [reloadOnLocaleChanged]);

    return (
        <React.Fragment>
            <Header heroImage="donate.jpg" activeSite="donate" />
            <section id="section">
            { loading ? <LoadingSpinner /> :
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-12 d-flex">
                            <div className="blog-entry align-self-stretch">
                                <div className="text d-block m-4">
                                    <div className="row d-flex contact-info">
                                        { serverDown || !donate ? <NoContent/> :
                                                <> 
                                                <div className="col-md-12 pt-4">
                                                <div><h3 className="card-title">{donate.title}</h3></div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div>{parse(donate.text)}</div>
                                                </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </section>
        </React.Fragment>
    )
}
export default Donate;