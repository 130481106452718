import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/home/home';
import About from './pages/about/about';
import Blog from './pages/blog/blog';
import BlogDetail from './pages/blog/blogDetail';
import Contact from './pages/contact/contact';
import Project from './pages/project/project';
import ProjectDetail from './pages/project/projectDetail';
import Donate from './pages/donate/donate';
import Privacy from './pages/privacy/privacy';
import Imprint from './pages/imprint/imprint';

import NotFound from './pages/notfound/notfound';

import Footer from './layout/footer/footer';

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blogDetail" component={BlogDetail} />
            <Route exact path="/project" component={Project} />
            <Route exact path="/projectDetail" component={ProjectDetail} />
            <Route exact path="/donate" component={Donate} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/imprint" component={Imprint} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
