import React from 'react';

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Header from '../../layout/header/header';
import ReactTooltip from 'react-tooltip';
import { isDesktop } from "react-device-detect";

function Contact() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Header heroImage="contact.jpg" activeSite="contact" />
            <section id="section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-12 d-flex">
                            <div className="blog-entry align-self-stretch">
                                <div className="text d-block m-4">
                                    <div className="row d-flex contact-info">
                                        <div className="col-md-12 pt-4">
                                            <div><h3 className="card-title">{t('contact.title')}</h3></div>
                                        </div>
                                        <div className="col-md-12 mb-4" />
                                        <div className="col-md-3">
                                            <div><h5>{t('contact.address.title')}</h5></div><div>{parse(t('contact.address.detail'))}</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div><h5>{t('contact.phone.title')}</h5></div><div>{parse(t('contact.phone.detail'))}</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div><h5>{t('contact.email.title')}</h5></div><div>{parse(t('contact.email.detail'))}</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div><h5>{t('contact.website.title')}</h5></div><div>{parse(t('contact.website.detail'))}</div>
                                        </div>
                                        <div className={ isDesktop ? "" : "col-md-8"} /> 
                                        <div className={ isDesktop ? "col-md-12" : "col-md-4"}>
                                            <p style={{ textAlign: 'right' }} data-tip={parse(t('contact.buttons.map'))} >
                                                <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://www.openstreetmap.org/?mlat=52.28297&amp;mlon=8.89418#map=17/52.28297/8.89418"><span className="icon-map"></span></a>
                                                <ReactTooltip globalEventOff="click" type="dark" />
                                            </p>
                                            <iframe title=" " style={{width:'100%', height:'400px'}} frameBorder="0" scrolling="no" src="https://www.openstreetmap.org/export/embed.html?bbox=8.890535831451418%2C52.28122773565601%2C8.897831439971926%2C52.28470638888341&amp;layer=mapnik&amp;marker=52.28296709640679%2C8.89418363571167" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </React.Fragment>
    )
}
export default Contact;