import React from 'react';

import { withTranslation } from "react-i18next";

import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

import { RemoteImage } from '../../components/picture';
import { isDesktop } from "react-device-detect";

import NavItems from './navitems';

function Navbar(props) {    
    const logoUrl = props.logo;
    const transparencyUrl = props.transparency;
    const activeSite = props.activeSite;

    const transparencyLogo = !transparencyUrl ? <span/> : <a href="https://www.transparency.de" target="_blank" rel="noreferrer"><RemoteImage name={transparencyUrl} alt="Transparency Logo" style={{ paddingLeft: '1em', width: isDesktop ? '200px' : '120px', height: 'auto' }} /></a>;

    const [windowInnerWidth, setWindowInnerWidth] = React.useState(window.innerWidth);

    const updateLogoPosition = () => {
        setWindowInnerWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", updateLogoPosition);
        return () => window.removeEventListener("resize", updateLogoPosition);
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-light ftco_navbar bg-light ftco-navbar-light" id="ftco-navbar">
            <div className="container-fluid">
                <Link to="/"><RemoteImage name={logoUrl} alt="Logo" style={{ width: '150px', height: 'auto' }} /></Link>

                { !(windowInnerWidth > 1300) ? transparencyLogo : <span/> }

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="oi oi-menu"/>
                </button>

                <div className="collapse navbar-collapse text-right" id="ftco-nav">
                	{isDesktop 
                    ? <Scroll to="content" spy={true} smooth={true} duration={2000} className="navbar-nav"><NavItems activeSite={activeSite}/></Scroll>
                    : <NavItems activeSite={activeSite}/>
                    }
                </div>
                { windowInnerWidth > 1300 ? transparencyLogo : <span/> }
            </div>
        </nav>
    )
}
export default withTranslation()(Navbar)