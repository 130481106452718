// defined in ".env" file respectively in docker(-compose) files
// ATTENTION: for the env constants they always have to start with "REACT_APP_"
const HOME_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/content/home/';
const ABOUT_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/content/about/';
const BLOGS_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/content/blogs/';
const DONATE_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/content/donate/';
const PROJECTS_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/content/projects/';

const PICTURE_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/images/';
const MEMBERSHIP_APPLICATION_URL = process.env.REACT_APP_STATIC_CONTENT_URL + '/applicationform/';

export { HOME_URL, ABOUT_URL, BLOGS_URL, DONATE_URL, PROJECTS_URL, PICTURE_URL, MEMBERSHIP_APPLICATION_URL} 