import React from "react";

import { withTranslation } from 'react-i18next';
import parse from "html-react-parser";

import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { RemoteBackground } from '../../components/picture';

import BlogService from '../../pages/blog/blogService';
import { NoContentSmall } from "../../components/nocontent";
import { LoadingSpinnerSmall } from "../../components/loadingspinner";

class RecentBlogs extends React.Component {
    interval = null;

    constructor(props) {
        super(props)

        this.state = {
            blogs: [],
            loading: false,
            serverDown: false
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.getData();
        }, 60000);

        this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getData = () => {
        this.setState({loading: true});
        BlogService.getBlogs().then((res) => {
            const data = res.data;
            this.setState({
                blogs: data.models.slice(0, 2),
                loading: false,
                serverDown: false
            });
        })
        .catch(error => {
            this.setState({
                loading: false,
                serverDown: true
            });
        });
    }

    render() {
        const { t } = this.props;
        const blogs = this.state.blogs;
        const loading = this.state.loading; 
        const serverDown = this.state.serverDown;

        return (
            <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">{t('footer.blog.title')}</h2>
                { loading ? <LoadingSpinnerSmall/> : serverDown ? <NoContentSmall/> : 
                    blogs.map(
                        (blog, index) =>
                            <div key={index} className="block-21 mb-4 d-flex">
                                <RemoteBackground className="blog-img mr-4" name={blog.pictureThumbnail} type="a"/>
                                <div className="text">
                                    <Scroll to="content" spy={true} smooth={true}>
                                        <Link to={{
                                            pathname: '/blogDetail',
                                            state: {
                                                blogDetail: blog
                                            }
                                        }}>
                                            <h3 className="heading" style={{color: 'rgb(248, 111, 45)'}}>{blog.title}</h3>
                                            <div className="meta">
                                                <div className="meta mb-3">
                                                    <span className="icon-calendar"></span> {t('app.technical.date.short', { date: new Date(blog.publishAt) })}
                                                    <span className="icon-person" style={{paddingLeft:'10px'}}></span> {parse(blog.author)}
                                                </div>
                                                {
                                                    blog.text &&  
                                                    parse(blog.text.substring(0, 100) 
                                                        + ' ' + (blog.text.length > 100 ? '...' : ''))
                                                }
                                            </div>
                                        </Link>
                                    </Scroll>
                                </div>
                            </div>
                    )
                }
            </div>
        )
    }
}
export default withTranslation()(RecentBlogs);