import React from 'react';

import { ToastContainer } from 'react-toastify';
import './toast.css';

import Navbar from './navbar'
import Hero from './hero'

function Header(props) {
    const navbarActiveSite = props.activeSite;
    const heroImage = props.heroImage;

    return (
        <React.Fragment>
            <ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
            <Navbar logo="logo.png" transparency="transparency.png" activeSite={navbarActiveSite}/>
            <span id="header"/>
            <Hero image={heroImage}/>
            <span id="content"/>
        </React.Fragment>
    )
}

export default Header;