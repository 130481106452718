import React from "react";

import { Redirect, withRouter } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import parse from "html-react-parser";

import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { RemoteBackground } from '../../components/picture';

import Header from "../../layout/header/header";
import BlogService from './blogService';

class BlogDetail extends React.Component {
    interval = null;

    constructor(props) {
        super(props)

        this.state = {
            blogs: []
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.getData();
        }, 20000);

        this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getData = () => {
        BlogService.getBlogs(0, 10).then((res) => {
            const data = res.data;
            this.setState({
                blogs: data.models
            });
        });
    }

    render() {
        const { t } = this.props;
        const blogs = this.state.blogs;
        const { location } = this.props
        const selectedBlog = location.state.blogDetail;

        if (location.state === undefined) {
            return <Redirect to="/blog" />
        }

        return (
            <React.Fragment>
                <Header heroImage="blog.jpg" activeSite="blog" />

                <section className="ftco-degree-bg">
                    <div className="container">
                        <div className="row">
                            <div className="blog-entry align-self-stretch col-md-8" style={{padding: '0'}}>
                                <RemoteBackground className="block-20" name={selectedBlog.picture} type="a"/>
                                <div className="text p-4 d-block">
                                    <h2 className="mb-3">{parse(selectedBlog.title)}</h2>
                                    <div>{selectedBlog.text ? parse(selectedBlog.text) : ''}</div>
                                </div>
                            </div>
                            <div className="col-md-4 sidebar">
                                <div className="sidebar-box">
                                    <h3>{t('footer.blog.title')}</h3>
                                    {
                                        blogs.map(
                                            (blog, index) =>
                                            <Scroll key={index} to="content" spy={true} smooth={true}>
                                                <Link to={{
                                                    pathname: '/blogDetail',
                                                    state: {
                                                        blogDetail: blog
                                                    }
                                                }}>
                                                <div className="block-21 mb-4 d-flex">
                                                    <RemoteBackground className="blog-img mr-4" name={blog.pictureThumbnail} type="span"/>
                                                    <div className="text">
                                                        <h3 className="heading">{blog.title}</h3>
                                                        <div className="meta">
                                                            <div className="meta mb-3">
                                                                <span className="icon-calendar"></span> {t('app.technical.date.short', { date: new Date(blog.publishAt) })}
                                                                <span className="icon-person" style={{paddingLeft:'10px'}}></span> {parse(blog.author)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>
                                            </Scroll>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(withRouter(BlogDetail))
