import React from 'react';

import i18n from '../../locales/i18n';
import { withTranslation } from "react-i18next";
import { RemoteImage } from '../../components/picture';

import { Link } from 'react-router-dom';

function NavItems(props) {
    const { t } = props;
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const activeSite = props.activeSite;
    const activeSiteUrl = "/" + activeSite;
    
    return (
        <ul className="navbar-nav ml-auto">
            <li className={activeSite === '' ? 'nav-item active' : 'nav-item'}><Link to="/" className="nav-link">{t("links.home")}</Link></li>
            <li className={activeSite === 'blog' ? 'nav-item active' : 'nav-item'}><Link to="/blog" className="nav-link">{t("links.blog")}</Link></li>
            <li className={activeSite === 'project' ? 'nav-item active' : 'nav-item'}><Link to="/project" className="nav-link">{t("links.project")}</Link></li>
            <li className={activeSite === 'donate' ? 'nav-item active' : 'nav-item'}><Link to="/donate" className="nav-link">{t("links.donate")}</Link></li>
            <li className={activeSite === 'contact' ? 'nav-item active' : 'nav-item'}><Link to="/contact" className="nav-link">{t("links.contact")}</Link></li>
            <li className={activeSite === 'about' ? 'nav-item active' : 'nav-item'}><Link to="/about" className="nav-link">{t("links.about")}</Link></li>
            <li className="nav-item" style={{ display: 'flex', whiteSpace: 'nowrap', justifyContent: 'right', alignItems: 'center' }}>
                <Link to={activeSiteUrl} style={{ paddingLeft: '0.5em', paddingBottom: '0.25em' }} onClick={() => changeLanguage('de')}><RemoteImage name="german_flag.svg" alt="German" width="18px" height="18px"></RemoteImage></Link>
                <Link to={activeSiteUrl} style={{ paddingLeft: '0.5em', paddingBottom: '0.25em' }} onClick={() => changeLanguage('en')}><RemoteImage name="english_flag.svg" alt="English" width="18px" height="18px"></RemoteImage></Link>
            </li>
        </ul>
    )
}

export default withTranslation()(NavItems);