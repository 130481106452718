import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll'

function Navbar() {
    const { t } = useTranslation();

    return (
        <div className="col-md-2">
            <div className="ftco-footer-widget mb-4 ml-md-4">
                <h2 className="ftco-heading-2">{t('footer.links.title')}</h2>
                <Scroll to="content" spy={true} smooth={true} duration={2000}>
                <ul className="list-unstyled">
                    <li>
                        <Link to="/" className="py-2 d-block">{t("links.home")}</Link>
                    </li>
                    <li>
                        <Link to="/blog" className="py-2 d-block">{t("links.blog")}</Link>
                    </li>
                    <li>
                        <Link to="/project" className="py-2 d-block">{t("links.project")}</Link>
                    </li>
                    <li>
                        <Link to="/donate" className="py-2 d-block">{t("links.donate")}</Link>
                    </li>
                    <li>
                        <Link to="/contact" className="py-2 d-block">{t("links.contact")}</Link>
                    </li>
                    <li>
                        <Link to="/about" className="py-2 d-block">{t("links.about")}</Link>
                    </li>
                </ul>
                </Scroll>
            </div>
        </div>
    )
}
export default Navbar;